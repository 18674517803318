import React from 'react';
import './App.css';
import Blinky from './Blinky';
import Footer from './Footer';
import Slider from './Slider';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <div className='container'>
      <div className='row'>
      <div className = 'col col-md-3'>
        <Blinky initialDelay={3000} lightStatus="on"></Blinky>
      </div>
      <div className = 'col col-md-9'>
        <Slider></Slider>
      </div>
      </div>
      <div className ='row'>
      <div className='col'>
        <br />
      <hr style={{ width: "75%", color: "#fff" }} />
      <Footer />
      </div>
      </div>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';

const Blinky = props => {

const [lightStatus, setLightStatus] = useState('off');
const triggers = [200,1000,1100,1200,1300,1800]
const [mycounter, setMycounter] =useState(0)
const blink = () => {
    if (triggers.includes(mycounter)){
        if (lightStatus === 'on') 
        {
          //  console.log( 'turningoff')
            setLightStatus('off')} 
        else 
        { 
           // console.log( 'turningon')
            setLightStatus('on')
        }
    }
//    console.log(mycounter)
    setMycounter(mycounter + 100)
}
//if (!lightStatus) {timer = setInterval(blink,1000);}

useEffect(()=>{
    if (mycounter < 5000)
    {setTimeout(blink,50);}
    return () => {clearTimeout()}
})

    return (
        <div className='littlelight sticky-top'>
             <img src={`/images/logo-filament-${lightStatus}.png`} 
             alt='logo' style={{width:'150px', paddingTop:'80px', paddingLeft:'55px'}} />
             <div style={{paddingTop:'200px'}}>
             Little Light Design - Holdrege, NE
             </div>
        </div>
        
    )
}

 export default Blinky;
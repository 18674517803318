import React, { useState, useEffect } from 'react';
import './slider.css';
const Slider = props => {



return (
<>
<div class="scroll-divider"></div>
<div class="bb-para" id="para1">
  <h2>Interiors</h2>
</div>  
<div class="scroll-divider"></div>

<div class="bb-para" id="para2">
  <h2>We love Puppies</h2>
</div>  
<div class="scroll-divider"></div>

<div class="bb-para" id="para3">
  <h2>We clean up after puppies</h2>
</div>  
<div class="scroll-divider"></div>
</>
)}
export default Slider;